import React from 'react'
import Navbar from './navbar'
import Getintouch from './getin_tuch'
import Footer from './footer'
import About1 from './about1'

function About_us() {
    return (
        <>
            <Navbar />
            <div className="pt-18 pb-38 h-screen flex flex-col justify-center items-center bg-[#0f072c]">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto">

                </div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
                        since 2022                    
                        </p>
                    <span className="text-3xl sm:text-6xl font-lucida font-bold text-white ">
                       Little bit about
                        <br></br> <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500'>
                            our journey
                        </span>
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        Story Of Algoarts
                    </p>
                </div>
            </div>
            <About1/>
            <Getintouch />
            <Footer />
        </>
    )
}

export default About_us