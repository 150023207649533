import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HCaptcha from 'react-hcaptcha'; // Import the hCaptcha component

const Ccform = () => {
    const form = useRef();
    const [captchaValue, setCaptchaValue] = useState('');// Store the hCaptcha value

    const sendEmail = async (e) => {
        e.preventDefault();
        // Get form values
        const userName = form.current.user_name.value.trim();
        const userEmail = form.current.user_email.value.trim();
        const message = form.current.message.value.trim();

        // Check for empty fields
        if (!userName || !userEmail || !message) {
            toast.error('Please fill in all fields.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark',
            });
            return;
        }

        // Check for a valid email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userEmail)) {
            toast.error('Please enter a valid email address.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark',
            });
            return;
        }

        // Check if hCaptcha value is empty
        if (!captchaValue) {
            toast.error('Please compleate hCaptcha verification failed.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark',
            });
            return;
        }

        try {

            await emailjs.sendForm(
                'service_z4kvkzo',
                'template_4vetssr',
                form.current,
                'TXpemPB0DW1UJTeqc'
            );

            // Show a success toast notification
            toast.success('Message sent!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000, // Auto-close the notification after 5 seconds
                theme: 'dark',
            });

            // Reset the form fields
            form.current.reset();
            setCaptchaValue(''); // Reset hCaptcha value
        } catch (error) {
            console.error('Error sending email:', error);

            // Show an error toast notification
            toast.error('Error sending email. Please try again later.', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleCaptchaChange = (value) => {
        // Store the hCaptcha value when it changes
        setCaptchaValue(value);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-[#F8F9FF]">
            <div className="w-full p-4 md:p-8 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto">
                <div className="flex flex-col lg:flex-row">
                    <h2 className="mt-3 text-2xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Contact us</h2>
                </div>
                <form
                    ref={form}
                    onSubmit={sendEmail}
                >
                    <div className="mb-6 mt-4">
                        <label htmlFor="name" className="block text-green-900 font-semibold mb-2">
                            Your Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="user_name"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-green-900 font-semibold mb-2">
                            Contact Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="user_email"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-green-900 font-semibold mb-2">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        ></textarea>
                    </div>
                    <div className="flex-none rounded-md mx-2 py-2">
                        <HCaptcha
                            sitekey="b585d917-684c-4ca1-894d-e2be83428046"
                            onVerify={handleCaptchaChange}
                        />
                    </div>
                    <button
                        type="submit"
                        className="mt-4 bg-blue-900 text-white font-semibold py-2 px-4 rounded-lg hover:bg-gradient-to-r to-emerald-600 from-sky-400"

                    >
                        Drop your requet
                    </button>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};
export default Ccform;




