import React from 'react'
import { ChevronRightIcon, PaperClipIcon, } from '@heroicons/react/20/solid'
import Footer from '../footer'
import Navbar from '../navbar'


function Code_of_conduct() {
    return (
        <div>
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                <Navbar />
                <div className="absolute inset-0 -z-10 overflow-hidden">
                    <svg
                        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M100 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                    </svg>
                </div>
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-indigo-600">Algoarts Technologies Private Limited </p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Code of Conduct V.2</h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700">
                                    01/13/2024
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                        <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
                            <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                            <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                            <div className="mx-auto max-w-2xl lg:max-w-4xl">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <div className="flex w-0 flex-1 items-center">
                                                    <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">Code of Conduct V1 .pdf</span>
                                                        <span className="flex-shrink-0 text-gray-400">1.8mb</span>
                                                    </div>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    <a href="https://drive.google.com/file/d/1PQxF8bZw40BCXzWZsry_bCKC9at4Fz_c/view?usp=sharing" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                        Download
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </dd>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg ">
                                <p className='text-justify'>
                                    <span class="mr-2">🎉</span>
                                    At Algoarts Technologies, we are committed to maintaining a workplace culture that fosters respect, integrity, and professionalism.
                                    Our success as a company is built upon the foundation of ethical behavior and the highest standards of conduct. This Code of Conduct
                                    outlines the principles and expectations that guide our actions as employees, contractors, and representatives of Algoarts Technologies.
                                </p>
                                <ul role="list" className="mt-8 space-y-8 text-gray-600">
                                    <li className="flex gap-x-3">
                                        <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Integrity and Honesty</strong>
                                            <ol class="list-decimal pl-5">
                                                <li class="mb-2">We are committed to honesty and transparency in all our interactions and communications.</li>
                                                <li class="mb-2">We do not engage in or condone deceptive, fraudulent, or dishonest practices.</li>
                                                <li class="mb-2">We protect and respect confidential and proprietary information.</li>
                                            </ol>
                                        </span>

                                    </li>
                                    <li className="flex gap-x-3">
                                        <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Respect and Inclusion</strong>
                                            <ol class="list-decimal pl-5">
                                                <li class="mb-2">We treat all individuals with respect and dignity, regardless of their background, identity, or position.</li>
                                                <li class="mb-2">Discrimination, harassment, or any form of offensive behavior is strictly prohibited.</li>
                                                <li class="mb-2">We promote diversity and inclusion and strive for an inclusive work environment.</li>
                                            </ol>
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Professionalism</strong>
                                            <ol class="list-decimal pl-5">
                                                <li class="mb-2">We uphold high professional standards in our work, appearance, and conduct.</li>
                                                <li class="mb-2">We do not engage in behavior that compromises the reputation or credibility of Algoarts Technologies.</li>
                                                <li class="mb-2">Conflict resolution is handled professionally and constructively.</li>
                                            </ol>
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Compliance with Laws and Regulations</strong>
                                            <ol class="list-decimal pl-5">
                                                <li class="mb-2">We comply with all applicable laws, regulations, and company policies.</li>
                                                <li class="mb-2">Any concerns about legal or ethical issues should be reported promptly.</li>                                        </ol>
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ChevronRightIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Confidentiality and Data Protection</strong>
                                            <ol class="list-decimal pl-5">
                                                <li class="mb-2">We safeguard sensitive information, including customer data and trade secrets.</li>
                                                <li class="mb-2">We respect privacy laws and protect personal data.</li>
                                            </ol>
                                        </span>
                                    </li>
                                </ul>
                                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Conclusion.</h2>
                                <p className="mt-6 text-justify">
                                    Our commitment to this Code of Conduct is essential to maintaining the reputation and success of Algoarts Technologies.
                                    By adhering to these principles, we contribute to a positive work environment and ensure that our actions align with our core values and mission.
                                    Every member of our organization is expected to read, understand, and adhere to this Code of Conduct.
                                    It is the responsibility of all employees to promote a culture of ethics and integrity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Code_of_conduct
