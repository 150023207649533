import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Navbar from '../components/navbar';
import Getintouch from '../components/getin_tuch';
import Footer from '../components/footer';
import logo from './../logo192.png'

function Blog() {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    useEffect(() => {
        const fetchPosts = async () => {
            const response = await fetch('https://chethakalakshitha.xyz/algoarts/backend/blogs');
            const data = await response.json();
            setPosts(data);
        };
        fetchPosts();
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(posts.length / postsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Helper function to extract first paragraph and first image
    const getFirstParagraphAndImage = (content) => {
        let firstParagraph = '';
        let firstImage = '';
        content.forEach(item => {
            if (!firstParagraph && item.type === 'paragraph') {
                firstParagraph = item.content;
            }
            if (!firstImage && item.type === 'image') {
                firstImage = item.content;
            }
        });
        return { firstParagraph, firstImage };
    };

    return (
        <>
            <Navbar />
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <h1 className="mt-2 text-3xl font-poppins tracking-tight sm:text-5xl">
                        <span className="mt-2 text-3xl font-bold font-poppins tracking-tight text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 sm:text-7xl">
                            Algoarts
                        </span>
                        <span className="mt-2 text-3xl font-bold font-poppins tracking-tight text-transparent bg-clip-text bg-gradient-to-r to-pink-600 from-sky-600 sm:text-5xl transform inline-block pb-4">
                             Academy
                        </span>
                    </h1>
                    <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {currentPosts.map((post) => {
                            const { firstParagraph, firstImage } = getFirstParagraphAndImage(post.content);
                            return (
                                <article key={post._id} className="flex max-w-xl flex-col items-start justify-between">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time dateTime={post.createdAt} className="text-gray-500">
                                            {new Date(post.createdAt).toLocaleDateString()}
                                        </time>
                                        <Link to={`/academy/blog/${post._id}`} className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                                            {post.tag}
                                        </Link>
                                    </div>
                                    <br />
                                    <div className="group relative">
                                        {firstImage && <img src={firstImage} alt="Blog post" />}
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <Link to={`/academy/blog/${post._id}`}>
                                                <span className="absolute inset-0" />
                                                {post.heading}
                                            </Link>
                                        </h3>
                                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-justify text-gray-600">{firstParagraph}</p>
                                        <span className="font-poppins text-lexend text-gray-600 hover:text-sky-600">Read more...</span>
                                    </div>
                                    <div className="relative mt-8 flex items-center gap-x-4">
                                        <img src={logo} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                                        <div className="text-sm leading-6">
                                            <p className="font-semibold text-gray-900">{post.author}</p>
                                            <p className="text-gray-600">{post.designation}</p>
                                        </div>
                                    </div>
                                </article>
                            );
                        })}
                    </div>
                    {/* Pagination controls */}
                    <div className="flex justify-center mt-12">
                        <div className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Previous
                            </button>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => paginate(i + 1)}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === i + 1
                                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                        : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-50'
                                        }`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Getintouch />
            <Footer />
        </>
    )
}

export default Blog;
