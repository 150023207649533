import React from 'react';
import sucess from './../accets/sucess.png';
import Reviews from '../reviews/reviews';

function Success_stories() {
    return (
        <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
            {/* Watermark */}

            <div className="flex flex-col lg:flex-row justify-between mx-4 sm:mx-8 sm:ps-28">
                <div className="lg:w-3/5">
                    <div className="py-8 text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
                        <p className="font-lexend mt-8 py-4">Success Stories</p>
                    </div>


                    <div className="py-4  max-w-5xl marquee-text text-3xl font-poppins text-gray-600 hover:text-pink inline-block " >
                        Our commitment is to create innovative products and improve user experiences for both new and established businesses.
                    </div>
                    <div className=" pt-4 marquee-text text-lg font-poppins text-gray-800 sm:text-xl">
                        <div className="w-full break-all">
                            <p className="mb-2 leading-normal"style={{wordSpacing: -1 ,}}>
                                Delve into our success stories where we've collaborated with startups navigating
                                rapid growth phases and established enterprises aiming for digital transformation.
                                Experience firsthand how our innovative software engineering solutions have driven
                                tangible business outcomes and enhanced user experiences across various industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full py-20 lg:mt-0">
                    <Reviews />
                </div>
            </div>
        </div>
    )
}

export default Success_stories;
