import React from 'react'
import Navbar from '../navbar'
import Getintouch from '../getin_tuch'
import Footer from '../footer'
import 'aos/dist/aos.css';
import Search from '../../accets/search.png'
import Website from '../../accets/website.png'
import Resource from '../../accets/nearby.png'
import Commen_call from '../../services/meeting api/commen';

function Product_development() {
    return (
        <>
            <Navbar />
            {/* section1 */}
            <div className="mt-16 relative py-32 flex flex-col justify-center items-center bg-[#0f072c] overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <span className="text-4xl sm:text-7xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500">
                        Transforming Ideas into<br />Market-Ready Innovations..
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        From initial brainstorming sessions to final product launch, we focus on delivering high-quality,
                        user-centric solutions that drive success. Leveraging the latest technologies and industry best
                        practices, we transform your unique ideas into groundbreaking products that stand out.
                    </p>
                </div>
            </div>
            {/* section1 */}
            <div className="bg-[#F8F9FF] py-12 relative">
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Insight</span>
                </div>
                <div className="flex justify-center items-center">
                    <div className="marquee-text text-center text-xl sm:text-2xl font-lucida font-bold">
                        <p className="py-4 font-lexend w-2/5 mx-auto text-gray-500">
                        The full service we are offering is
                        designed to meet your business needs.</p>
                        <p className="font-lucida  mx-auto bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ">
                        Why is Quality by Design often a philosophy rather than standard practice?</p>
                        <p className="font-lexend py-4 w-4-5 w-4/5 mx-auto text-gray-500">
                        Providence helps automate the entire product development process, providing integrated solutions that not only follow industry best practices, but also allow you to collaborate across multiple sites while maintaining visibility and quality at each step.</p>
                    </div>
                </div>
            </div>
            {/* section2 */}
            <div className='bg-[#0f072c] py-16 relative'>
                <div className="flex flex-col md:flex-row gap-8 justify-center text-center max-w-6xl mx-auto">
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Search} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Custom Engineering
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight mt-3 text-left">
                        We assist you at every stage of the product engineering journey: from product ideation to implementation and support, we deliver custom product development services tailored to your business vision.                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Website} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Product Upgrade
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-left">
                        Itransition helps companies expand the features of their products to meet users’ demands and increase competitiveness through value-driven upgrades and extensions.                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Resource} /></div>
                        <div className="my-4"></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Project Rescue
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-gray-500 text-left">
                        With or without legacy project documentation in place, we can join at any stage to perform detailed product analysis, design the transformation roadmap, and help you extract maximum value from your software.                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#F8F9FF] min-h-screen py-12 relative">
                <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
                <div class="py-6 px-4 md:px-8">
                    <div data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <div class="text-center text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl  tracking-wide mb-2 md:mb-0 md:text-left md:ml-32">
                            Clients' Recommendation
                        </div>
                        <h3 class="text-center text-gray-400 font-bold mb-4 md:text-left md:ml-32">
                            Perspectives of Product experinces
                        </h3>
                    </div>
                    <div class="h-8"></div>
                    <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                        <div data-aos="fade-right">
                            <div class="flex flex-col items-center">
                                <img src="https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3BsYXlcLzBiN2Y0ZTliLWY1OWMtNDAyNC05ZjA2LWIzZGMxMjg1MGFiNy0xOTIwLTEwODAuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo4Mjh9fX0=" loading="lazy" alt="" class="h-80 w-60 rounded-lg object-cover" />
                                {/* <img src="https://cdn.prod.website-files.com/64abaf64480a5f17c04722a1/64faa7c5b19dd4fbf7d250f1_metjip-icon.svg" loading="lazy" width="60" alt="" class="" /> */}
                            </div>
                        </div>
                        <div data-aos="fade-left">
                            <div class="p-4">
                                <div class="text-lg text-gray-600 text-center font-lucida" style={{ wordSpacing: 3 }}>
                                    We've had a fantastic experience with Algoarts providing technology support for Walton's.
                                    Working closely with them on our legal product, MetJip, has been enlightening. Algoarts not
                                    only showcases exceptional engineering skills but also boasts an exemplary product management
                                    approach.

                                    They initiated insightful workshops early on to understand our clients' needs deeply
                                    and craft a robust product strategy. Their customer-focused ethos allowed them to swiftly adapt
                                    to evolving requirements based on user feedback throughout the development process.
                                    This partnership has been instrumental in delivering a product that truly meets and exceeds our
                                    expectations.
                                </div>
                                <div class="h-4"></div>
                                <div class="grid grid-cols-1 sm:grid-cols-3 items-center">
                                    <img src="https://cdn.prod.website-files.com/64abaf64480a5f17c04722a1/64b4f3f22b7076a9528a5bb0_quote-icon.svg" loading="lazy" width="48" alt="" class="col-span-1" />
                                    <div class="col-span-2">
                                        <div class="font-semibold">Mr.Hasitha Gamage</div>
                                        <div class="text-sm">Co-founder – Waltons</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Commen_call/>
            </div>
           
            {/* section2 */}
            <Getintouch />
            <Footer />
        </>
    )
}

export default Product_development