import React from 'react'

function Ourworks1() {
    return (
        <div>
            <section>
                <div className="bg-[#F8F9FF] py-16  relative">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
                        <div className="flex flex-wrap items-center justify-between -mx-4">
                            <div className="w-full px-4 lg:w-6/12">
                                <div className="flex items-center -mx-3 sm:-mx-4">
                                    <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                        <div className="py-3 sm:py-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/keynote-presentation.svg'
                                                alt="About Image 1"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                        <div className="py-3 sm:py-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/app-launch.svg'
                                                alt="About Image 2"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                        <div className="relative z-10 my-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/man-riding-a-rocket.svg'
                                                alt="About Image 3"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                                <div className="mt-10 lg:mt-0">
                                    <span className="block mb-4 text-lg font-semibold text-gray-500">
                                        Turning Ideas into Impact
                                    </span>
                                    <h2 className="mb-5 text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                                        Our Commitment to Client Success
                                    </h2>
                                    <p className="mb-5 font-lexend marquee-text text-gray-500 text-xl">
                                        Algoarts is committed to transforming business ideas into digital success stories.
                                        We take pride in our diverse portfolio of clients from the UK, Australia, and Sri Lanka,
                                        providing reliable support across a wide range of industries. Each project is designed to
                                        reflect our clients’ unique vision, ensuring impactful, user-friendly solutions that achieve
                                        business goals. Explore how we make a difference.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-[#F8F9FF] relative">
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Empowering Solutions, Tailored Functionality to <br></br></span>
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Solve Any Business Challenge</span>
                </div>
                <div className=" marquee-text text-center text-xl sm:text-2xl font-lucida font-bold text-gray-500">
                    <p className="py-4 font-lexend">Industries We Serve.</p>
                </div>
            </div>
        </div>
    )
}

export default Ourworks1
