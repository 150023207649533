import React, { useState } from 'react';
import '../components/css/heroscreen.css'
import Call_modal from '../services/call_modle';
import Booking from '../../src/accets/booking.png'
function Make_a_call() {

    //call_us operation

    //call_us

    return (

        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-24 lg:overflow-visible lg:px-0 flex flex-col lg:flex-row">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-1/2 top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="w-full lg:w-1/2">
                <div className="py-2 text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
                    <p className="font-lexend mx-4 sm:mx-8 sm:ps-28 ">Tech Talks in 30</p>
                </div>

                <div className="py-4 mx-4 sm:mx-8 sm:ps-28 max-w-5xl marquee-text text-2xl font-poppins text-gray-600 hover:text-pink inline-block ">
                    Quick Fix or Deep Dive, <br></br> Choose Your 30-Minute Tech Adventure!
                </div>
                <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-lg font-poppins text-gray-800 pb-16 sm:text-justify">
                    <div className="w-full text-justify whitespace-pre-line">
                        <p className="mb-2 leading-normal">
                            Discover tailored tech support with our 30-minute sessions designed to meet your needs.
                            Whether you need a quick fix to resolve an urgent issue or prefer a deep dive to thoroughly
                            understand and tackle complex problems, we offer the flexibility to choose the type of support
                            that suits you best. Get expert assistance and enhance your tech skills in just 30-Minute.
                        </p>
                    </div>
                    <Call_modal />
                </div>
            </div>
            <div className="hidden lg:block lg:w-3/6 pr-2">
                <img
                    className="small-image"
                    src={Booking}
                    alt=""
                />
            </div>
        </div>
    );

}
export default Make_a_call;
