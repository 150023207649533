import React from 'react';

const industries = [
  { name: 'Finance', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/1.jpg' },
  { name: 'Healthcare', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/pexels-pixabay-40568.jpg' },
  { name: 'Education & Learning', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/4.jpg' },
  { name: 'Manufacturing & Retail', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/5.jpg' },
  { name: 'Export Market', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/export.jpg' },
  { name: 'Modern Agriculture', image: 'https://d2fv6gcd5x31n1.cloudfront.net/resources/images/3.jpg' },
];

const IndustriesSection = () => {
  return (
    <div className="bg-[#F8F9FF] py-6">      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Flex container for horizontal scroll on mobile */}
        <div className="flex overflow-x-auto space-x-4 snap-x snap-mandatory md:grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg shadow-lg snap-center min-w-[80%] sm:min-w-[50%] md:min-w-0"
            >
              <img
                src={industry.image}
                alt={industry.name}
                className="object-cover w-full h-64 transition-transform duration-300 group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white text-xl font-semibold">{industry.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustriesSection;
