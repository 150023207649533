
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import 'react-phone-input-2/lib/style.css';
import countryCodes from './country_codes';
import Uploading from '../accets/upload.gif'
import Sucess from '../accets/progress.gif'

const FormComponent = () => {

  

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
  
    const handleFileChange = (e) => {
      setFiles([...e.target.files]);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      setFiles([...e.dataTransfer.files]);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission and file upload logic
      console.log('Form submitted with files:', files);
    };
    

    return (

  
      <form className="contact-form w-full max-w-lg mx-auto mt-8" onSubmit={handleSubmit}>
      <div className="mb-4">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your name"
          required
          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
        />
      </div>
    
      {/* Phone number */}
      <div className="mb-4">
        <div className="flex flex-col sm:flex-row">
          <select
            id="country-code"
            name="country-code"
            required
            className="w-full sm:w-1/4 px-4 py-2 mr-0 sm:mr-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            {countryCodes.map((country) => (
              <option key={country.code} value={country.code}>
                {country.code} ({country.country})
              </option>
            ))}
          </select>
          <input
            type="tel"
            id="phone-number"
            name="phone-number"
            placeholder="Your phone number"
            required
            className="w-full sm:w-3/4 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
        </div>
      </div>
    
      <div className="mb-4">
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Your email"
          required
          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
        />
      </div>
    
      <div className="mb-2">
        <textarea
          id="message"
          name="message"
          rows="4"
          placeholder="Your message"
          required
          className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
        ></textarea>
      </div>
    
      {/* File Upload Section */}
      <div
        className={`mb-4 p-4 border-2 rounded-lg ${isDragging ? 'border-purple-500' : 'border-gray-300'} transition-colors`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileUpload"
          multiple
          onChange={handleFileChange}
          className="hidden"
        />
        <label
          htmlFor="fileUpload"
          className="block text-center text-gray-500 cursor-pointer"
        >
          {files.length > 0
            ? `Selected files: ${files.map((file) => file.name).join(', ')}`
            : 'Drag and drop files here or click to select files'}
        </label>
      </div>
    
      <div className="flex justify-end">
        <button
          type="submit"
          className="submit-btn w-full sm:w-auto h-12 px-4 sm:px-6 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center justify-center"
        >
          Submit your inquiry
        </button>
      </div>
    </form>
    
      
            

);

};

export default FormComponent;
















// import React, { useState } from 'react';

// const StepperForm = () => {
//     const [step, setStep] = useState(1);
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         contactNumber: '',
//         email: '',
//         trouble: ''
//     });

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const nextStep = () => {
//         if (step < 4) {
//             setStep(step + 1);
//         }
//     };

//     const prevStep = () => {
//         if (step > 1) {
//             setStep(step - 1);
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log(formData);
//     };

//     return (
//         <div className="relative max-w-4xl mx-auto p-6">
//             <form onSubmit={handleSubmit}>
//                 {step === 1 && (
//                     <div className="flex space-x-20">
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">First Name</label>
//                             <input
//                                 type="text"
//                                 name="firstName"
//                                 value={formData.firstName}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="First Name"
//                                 required
//                             />
//                         </div>
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Last Name</label>
//                             <input
//                                 type="text"
//                                 name="lastName"
//                                 value={formData.lastName}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Last Name"
//                                 required
//                             />
//                         </div>
//                     </div>
//                 )}
//                 {step === 2 && (
//                     <div className="flex space-x-20">
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Email Address</label>
//                             <input
//                                 type="tel"
//                                 name="contactNumber"
//                                 value={formData.contactNumber}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Contact Number"
//                                 required
//                             />
//                         </div>
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Phone Number</label>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Email Address"
//                                 required
//                             />
//                         </div>
//                     </div>
//                 )}
//                 {step === 3 && (
//                     <div>
//                         <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Describe Your Trouble</label>
//                         <textarea
//                             name="trouble"
//                             value={formData.trouble}
//                             onChange={handleChange}
//                             className="mt-2 bg-transparent border-transparent outline-none transition duration-300 w-full max-w-xl min-h-[5rem]"
//                             placeholder="Describe Your Trouble"
//                             required
//                         />
//                     </div>
//                 )}

//                 <div className="flex justify-start mt-6 space-x-4">
//                     {step > 1 && (
//                         <button
//                             type="button"
//                             onClick={prevStep}
//                             className="px-6 py-2 bg-gray-300 text-gray-700 shadow-sm hover:bg-gray-400 transition duration-300"
//                         >
//                             Back
//                         </button>
//                     )}
//                     {step < 3 && (
//                         <button
//                             type="button"
//                             onClick={nextStep}
//                             className="px-6 py-2 bg-blue-500 text-white shadow-sm hover:bg-[#0f072c] transition duration-300"
//                         >
//                             Next <span className="ml-2">{Math.round((step / 3) * 100)}%</span>
//                         </button>
//                     )}
//                     {step === 3 && (
//                         <button
//                             type="submit"
//                             className="px-6 py-2 bg-green-500 text-white shadow-sm hover:bg-green-600 transition duration-300"
//                         >
//                             Submit <span className="ml-2">{Math.round((step / 3) * 100)}%</span>
//                         </button>
//                     )}
//                 </div>
//             </form>
            
//         </div>
//     );
// };

// export default StepperForm;

