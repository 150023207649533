import React, { useState, useEffect } from 'react';
import Navbar from '../navbar';
import Footer from '../footer';
import Getintouch from '../getin_tuch';

function Careers() {
    const [isLoading, setIsLoading] = useState(true);
    const [showWebPage, setShowWebPage] = useState(false);
    const loadingImageUrl = 'https://rooster.jobs/static/Rooster_Logo_White.svg';


    const url = 'https://boards.rooster.jobs/16453';

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
            setShowWebPage(true);
        }, 5000);

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    return (
        <div>
            <Navbar />
            {/* section1 */}
            <div className="mt-16 relative py-32 flex flex-col justify-center items-center bg-[#0f072c] overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <span className="text-4xl sm:text-7xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500">
                        Be a Part of a  <br />Global Tech Powerhouse...
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                    From initial brainstorming sessions to final product launch, we focus on delivering high-quality, user-centric solutions that drive success. Leveraging the latest technologies and industry best practices, we transform your unique ideas into groundbreaking products that stand out.
                    </p>
                </div>
            </div>
            {/* section1 */}
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                {isLoading && (
                    <div>
                        <h2 class="animate-pulse mt-3 text-2xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Loading...</h2>
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>
            {showWebPage && (
                <iframe
                    title="Careers"
                    width="100%"
                    height="500px"
                    src={url}
                    allowFullScreen
                />
            )}
            <Getintouch />
            <Footer />
        </div>
    )
}

export default Careers