import { FaBlog, FaHome, FaMailBulk, FaUser } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();
    const handleBlog = (e) => {
        e.preventDefault();
        navigate("/admin/blog");
    }
    return (
        <div className="flex h-screen bg-gray-50">
            {/* Sidebar */}
            <div className="w-16 md:w-60 bg-white shadow-md">
                <div className="p-5 font-bold text-lg hidden md:block">Dashboard</div>
                <ul className="space-y-2">
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaHome className="text-xl" />
                        <span className="hidden md:inline">Home</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaUser className="text-xl" />
                        <span className="hidden md:inline">Profile</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaMailBulk className="text-xl" />
                        <span className="hidden md:inline">Messages</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200" onClick={handleBlog}>
                        <FaBlog className="text-xl" />
                        <span className="hidden md:inline">Blog</span>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="flex-1 p-10 text-2xl font-bold">
                Welcome to your beautiful dashboard
            </div>
        </div>
    );
}

export default Dashboard;
