import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Getintouch from "../components/getin_tuch";
import logo from './../logo192.png'

function BlogPage() {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(`https://chethakalakshitha.xyz/algoarts/backend/blogs/${id}`);
                const data = await response.json();
                if (response.ok) {
                    setBlog(data);
                } else {
                    console.error('Failed to fetch blog:', data);
                }
            } catch (error) {
                console.error('Error fetching blog:', error);
            }
        };
        fetchBlog();
    }, [id]);

    if (!blog) return <p>Loading...</p>;

    return (
        <>
            <Navbar />
            <div className="flex justify-center w-full h-full mt-8">
                <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white light:bg-gray-900 antialiased">
                    <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
                        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue light:format-invert">
                            <header className="mb-4 lg:mb-6 not-format">
                                <address className="flex items-center mb-6 not-italic">
                                    <img className="mr-4 w-16 h-16 rounded-full" src={logo} alt={blog.author}/>
                                    <div>
                                        <a href="#" rel="author" className="text-xl font-bold text-gray-900 dark:text-black">{blog.author}</a>
                                        <p className="text-base text-gray-500 dark:text-gray-400">{blog.designation}</p>
                                        <p className="text-base text-gray-500 dark:text-gray-400 ">
                                            <time pubdate dateTime={new Date(blog.createdAt).toISOString()} title={new Date(blog.createdAt).toLocaleDateString()}>
                                                {new Date(blog.createdAt).toLocaleDateString()}
                                            </time>
                                        </p>
                                    </div>
                                </address>
                                <h1 className="mb-4 text-3xl font-extrabold leading-tight text-justify text-gray-900 lg:mb-6 lg:text-4xl dark:text-gray600">
                                    {blog.heading}
                                </h1>
                            </header>
                            {blog.content.sort((a, b) => a.order - b.order).map((item, index) => {
                                switch (item.type) {
                                    case 'paragraph':
                                        return <p key={index} className="mb-4 text-justify font-poppins">{item.content}</p>;
                                    case 'heading':
                                        return <h2 key={index} className="text-xl font-bold">{item.content}</h2>;
                                    case 'subheading':
                                        return <h3 key={index} className="text-lg font-semibold">{item.content}</h3>;
                                    case 'image':
                                        return <img key={index} src={item.content} alt={`Content image ${index}`} className="my-4" />;
                                    default:
                                        return null;
                                }
                            })}
                            <p className='text-center'>Article End</p>
                        </article>
                    </div>
                </main>
            </div>
            <Getintouch />
            <Footer />
        </>
    );
}

export default BlogPage;
