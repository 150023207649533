import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import './css/nav.css';
import './css/header.css';
import {
    GlobeEuropeAfricaIcon, CloudArrowUpIcon, CodeBracketIcon, LockClosedIcon,
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import ProgressBar from './widgets/process_bar';
import logo from './../logo192.png'

const products = [
    { name: 'CUSTOM SOFTWARE DEVELOPMENT', description: 'When off-the-shelf software wont cut it', href: '/services/Custom_software_development', icon: CodeBracketIcon },
    { name: 'WEB APP DEVELOPMENT', description: 'Customize business solutions to digital products', href: '/services/Web_app_development', icon: GlobeEuropeAfricaIcon },
    { name: 'PRODUCT MANAGEMENT SERVICES    ', description: 'Your customers’ data will be safe and secure', href: '/services/product_management', icon: CloudArrowUpIcon },
]
const callsToAction = [

    { name: 'Contact for inquiry', href: 'tel:+94773112006', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="fixed-header">
            <nav className="mx-auto flex w-full items-center justify-start p-2 lg:px-8 shadow-sm" aria-label="Global">
                <div className="flex items-center">
                    <a href="https://algoarts.io/" className="-m-1.5 p-1.5 flex items-center">
                        <img className="h-14 w-auto" src={logo} />
                        <div className="ml-1">
                            <span className="text-2xl font-bold font-poppins text-gray-800">Algoarts</span>
                            <span className="block text-sm font-bold font-poppins text-gray-600 hidden sm:block">Turning Ideas into Digital Reality.</span>
                        </div>
                    </a>

                </div>
                {/* ham burger icon */}
                <div className="flex lg:hidden absolute top-2 right-2 mt-4 mr-6">
                    <button
                        type="button"
                        className="-m-2 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400  opacity-60"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-10 w-10" aria-hidden="true" />
                    </button>
                </div>
                {/* ham burger icon */}
                {/* servicebutton */}
                <Popover.Group className="hidden lg:flex lg:gap-x-10 ml-16">
                    <Popover className="relative">
                        <div className="group relative">
                            <Popover.Button className="flex items-center gap-x-1 text-lg text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                                Services
                                <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <div className="absolute left-0 top-full z-10 mt-8 w-screen max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 max-h-[calc(100vh-5rem)] overflow-y-auto">
                                <div className="p-4">
                                    {products.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:text-white"
                                        >
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                            </div>
                                            <div className="flex-auto">
                                                <a href={item.href} className="block font-semibold font-lexend">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                                <p className="mt-1 hover:text-white">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="divide-x divide-gray-900/5 bg-gray-50">
                                    {callsToAction.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:text-white"
                                        >
                                            <item.icon className="h-5 w-5 flex-none text-gray-400 hover:text-white" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Transition>
                    </Popover>
                    {/* servicebutton */}
                    {/* navitemlist */}
                    <div className="group relative">
                        <a href="/academy/blog" className="text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                            Blog
                            <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                        </a>
                    </div>
                    <div className="group relative">
                        <a href="/careers" className="text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                            Careers
                            <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                        </a>
                    </div>
                    <div className="group relative">
                        <a href="/our_works" className="text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                            Our Work
                            <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                        </a>
                    </div>
                    <div className="group relative">
                        <a href="/about_us" className="text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                            About Us
                            <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                        </a>
                    </div>
                    {/* navitemlist */}

                    <ProgressBar />
                </Popover.Group>
                {/* call button */}

                <div className="ml-auto flex items-center">
                    <div className="group relative hidden md:block">
                        <a href="tel:+94705035126" className="text-lg text-dark font-semibold font-lexend leading-6 text-gray-600">
                            Make a Call
                            <span className="absolute bottom-[-23px] left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-purple-400 to-pink-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                        </a>
                    </div>

                </div>
                {/* call button */}
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#0F072C] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Algoarts</span>
                            <img
                                className="h-8 w-auto"
                                src="https://contctcss.vercel.app/algoarts_logo.png"
                                alt="Algoarts Logo"
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <br></br>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="text-white flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                                                Services
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {[...products, ...callsToAction].map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <a
                                    href="/academy/blog"
                                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
                                >
                                    Blog
                                </a>
                                <a
                                    href="/careers"
                                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
                                >
                                    Careers
                                </a>                
                            </div>
                            
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}
// dfgdfgdfgd