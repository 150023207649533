import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './../css/bact_to_top.css'

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (

    <>
      {isVisible && (
        <div className="hidden md:block fixed bottom-40 right-24 z-50 p-3 secure-full bg-blue-900 text-white cursor-pointer hover:bg-blue-600 transition-all duration-300 scroll-to-top " style={{ backgroundColor: '#0f072c', cursor: 'pointer',position: 'fixed' ,transition: 'opacity 0.3s, visibility 0.3s' }} onClick={scrollToTop}>
          <FaArrowUp />
        </div>
      )}
    </>

  );
};

export default ScrollToTop;
