import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        const updateScrollPercentage = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollPosition = window.scrollY;

            const percentage = (scrollPosition / (documentHeight - windowHeight)) * 100;
            setScrollPercentage(percentage);
        };

        window.addEventListener('scroll', updateScrollPercentage);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', updateScrollPercentage);
        };
    }, []);

    return (
        <>
            {/* Desktop view */}
            <div className="fixed top-0 left-0 w-full h-2 bg-blue-1000 transition-all">
                <div
                    className="h-full bg-gradient-to-r from-purple-400 to-pink-600"
                    style={{ width: `${scrollPercentage}%` }}
                />
            </div>
            {/* Mobile view */}
            <div className="lg:hidden fixed bottom-0 left-0 w-full h-2 bg-blue-1000 transition-all">
                <div
                    className="h-full bg-gradient-to-r from-purple-400 to-pink-600 "
                    style={{ width: `${scrollPercentage}%` }}
                />
            </div>
        </>
    );
};

export default ProgressBar;
