import React, { useState, useEffect } from 'react';

function BlogTable() {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        async function fetchBlogs() {
            const response = await fetch('https://chethakalakshitha.xyz/algoarts/backend/blogs');
            const data = await response.json();
            if (response.ok) {
                setBlogs(data);
            } else {
                console.error('Failed to fetch blogs:', data);
            }
        }

        fetchBlogs();
    }, []);

    const handleDelete = async (id) => {
        const response = await fetch(`https://chethakalakshitha.xyz/algoarts/backend/blogs/${id}`, {
        // const response = await fetch(`http://localhost:4002/api/blogs/${id}`, {
            method: 'DELETE',
        });
        if (response.ok) {
            setBlogs(blogs.filter(blog => blog._id !== id));
        } else {
            console.error('Failed to delete the blog');
        }
    };

    return (
        <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 light:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Tag</th>
                        <th scope="col" className="px-6 py-3">Heading</th>
                        <th scope="col" className="px-6 py-3">Author</th>
                        <th scope="col" className="px-6 py-3">Designation</th>
                        <th scope="col" className="px-6 py-3">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {blogs.map((blog) => (
                        <tr key={blog._id} className="bg-white border-b light:bg-gray-900 light:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 light:text-black whitespace-nowrap">{blog.tag}</th>
                            <td className="px-6 py-4">{blog.heading}</td>
                            <td className="px-6 py-4">{blog.author}</td>
                            <td className="px-6 py-4">{blog.designation}</td>
                            <td className="px-6 py-4">
                                <button onClick={() => handleDelete(blog._id)} className="font-medium text-red-600 light:text-red-500 hover:underline">Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BlogTable;
