// src/countryCodes.js

const countryCodes = [
    { code: '+1', country: 'USA' },
    { code: '+44', country: 'UK' },
    { code: '+91', country: 'IND' },
    { code: '+94', country: 'SRL' },
    { code: '+61', country: 'AUS' },
    { code: '+81', country: 'JPN' },
    { code: '+86', country: 'CHN' },
    { code: '+49', country: 'GER' },
    { code: '+33', country: 'FRA' },
    { code: '+39', country: 'ITA' },
    { code: '+55', country: 'BRA' },
    { code: '+7',  country: 'RUS' },
    { code: '+34', country: 'ESP' },
    { code: '+31', country: 'NLD' },
    { code: '+47', country: 'NOR' },
    { code: '+46', country: 'SWE' },
    { code: '+41', country: 'CHE' },
    { code: '+45', country: 'DNK' },
    { code: '+64', country: 'NZL' },
    { code: '+60', country: 'MYS' },
    { code: '+65', country: 'SGP' },
    { code: '+62', country: 'IDN' },
    { code: '+63', country: 'PHL' },
    { code: '+66', country: 'THA' },
    { code: '+90', country: 'TUR' },
    { code: '+20', country: 'EGY' },
    { code: '+212', country: 'MAR' },
    { code: '+27', country: 'ZAF' },
    { code: '+254', country: 'KEN' },
    { code: '+234', country: 'NGA' },
    { code: '+974', country: 'QAT' },
    { code: '+966', country: 'SAU' },
    { code: '+971', country: 'ARE' },
    { code: '+92', country: 'PAK' },
    { code: '+880', country: 'BGD' },
    { code: '+84', country: 'VNM' },
    { code: '+82', country: 'KOR' },
    { code: '+56', country: 'CHL' },
    { code: '+57', country: 'COL' },
    { code: '+51', country: 'PER' },
    { code: '+52', country: 'MEX' },
    { code: '+54', country: 'ARG' },
    { code: '+60', country: 'MYS' },
    { code: '+61', country: 'AUS' },
    { code: '+1-268', country: 'ATG'},
    { code: '+1-242', country: 'BHS'},
    { code: '+1-246', country: 'BRB'},
    { code: '+1-441', country: 'BMU'},
    { code: '+1-345', country: 'CYM'},
    { code: '+1-767', country: 'DMA'},
    { code: '+1-809', country: 'DOM'},
    { code: '+1-473', country: 'GRD'},
    { code: '+1-876', country: 'JAM'},
    { code: '+1-664', country: 'MSR'},
    { code: '+1-721', country: 'SXM'},
    { code: '+1-758', country: 'LCA'},
    { code: '+1-868', country: 'TTO'},
    { code: '+1-869', country: 'KNA'},
    { code: '+1-784', country: 'VCT'},
    { code: '+1-284', country: 'VGB'},
    { code: '+1-340', country: 'VIR'},
    { code: '+213', country: 'DZA' },
    { code: '+216', country: 'TUN' },
    { code: '+218', country: 'LBY' },
    { code: '+220', country: 'GMB' },
    { code: '+221', country: 'SEN' },
    { code: '+222', country: 'MRT' },
    { code: '+223', country: 'MLI' },
    { code: '+224', country: 'GIN' },
    { code: '+225', country: 'CIV' },
    { code: '+226', country: 'BFA' },
    { code: '+227', country: 'NER' },
    { code: '+228', country: 'TGO' },
    { code: '+229', country: 'BEN' },
    { code: '+230', country: 'MUS' },
    { code: '+231', country: 'LBR' },
    { code: '+232', country: 'SLE' },
    { code: '+233', country: 'GHA' },
    { code: '+234', country: 'NGA' },
    { code: '+235', country: 'TCD' },
    { code: '+236', country: 'CAF' },
    { code: '+237', country: 'CMR' },
    { code: '+238', country: 'CPV' },
    { code: '+239', country: 'STP' },
    { code: '+240', country: 'GNQ' },
    { code: '+241', country: 'GAB' },
    { code: '+242', country: 'COG' },
    { code: '+243', country: 'COD' },
    { code: '+244', country: 'AGO' },
    { code: '+245', country: 'GNB' },
    { code: '+246', country: 'IOT' },
    { code: '+247', country: 'SHN' },
    { code: '+248', country: 'SYC' },
    { code: '+249', country: 'SDN' },
    { code: '+250', country: 'RWA' },
    { code: '+251', country: 'ETH' },
    { code: '+252', country: 'SOM' },
    { code: '+253', country: 'DJI' },
    { code: '+254', country: 'KEN' },
    { code: '+255', country: 'TZA' },
    { code: '+256', country: 'UGA' },
    { code: '+257', country: 'BDI' },
    { code: '+258', country: 'MOZ' },
    { code: '+260', country: 'ZMB' },
    { code: '+261', country: 'MDG' },
    { code: '+262', country: 'REU' },
    { code: '+263', country: 'ZWE' },
    { code: '+264', country: 'NAM' },
    { code: '+265', country: 'MWI' },
    { code: '+266', country: 'LSO' },
    { code: '+267', country: 'BWA' },
    { code: '+268', country: 'SWZ' },
    { code: '+269', country: 'COM' },
    { code: '+290', country: 'SHN' },
    { code: '+291', country: 'ERI' },
    { code: '+297', country: 'ABW' },
    { code: '+298', country: 'FRO' },
    { code: '+299', country: 'GRL' },
    { code: '+350', country: 'GIB' },
    { code: '+351', country: 'PRT' },
    { code: '+352', country: 'LUX' },
    { code: '+353', country: 'IRL' },
    { code: '+354', country: 'ISL' },
    { code: '+355', country: 'ALB' },
    { code: '+356', country: 'MLT' },
    { code: '+357', country: 'CYP' },
    { code: '+358', country: 'FIN' },
    { code: '+359', country: 'BGR' },
    { code: '+370', country: 'LTU' },
    { code: '+371', country: 'LVA' },
    { code: '+372', country: 'EST' },
    { code: '+373', country: 'MDA' },
    { code: '+374', country: 'ARM' },
    { code: '+375', country: 'BLR' },
    { code: '+376', country: 'AND' },
    { code: '+377', country: 'MCO' },
    { code: '+378', country: 'SMR' },
    { code: '+379', country: 'VAT' },
    { code: '+380', country: 'UKR' },
    { code: '+381', country: 'SRB' },
    { code: '+382', country: 'MNE' },
    { code: '+383', country: 'XKX' },
    { code: '+385', country: 'HRV' },
    { code: '+386', country: 'SVN' },
    { code: '+387', country: 'BIH' },
    { code: '+389', country: 'MKD' },
    { code: '+420', country: 'CZE' },
    { code: '+421', country: 'SVK' },
    { code: '+423', country: 'LIE' },
    { code: '+500', country: 'FLK' },
    { code: '+501', country: 'BLZ' },
    { code: '+502', country: 'GTM' },
    { code: '+503', country: 'SLV' },
    { code: '+504', country: 'HND' },
    { code: '+505', country: 'NIC' },
    { code: '+506', country: 'CRI' },
    { code: '+507', country: 'PAN' },
    { code: '+508', country: 'SPM' },
    { code: '+509', country: 'HTI' },
    { code: '+590', country: 'BLM' },
    { code: '+591', country: 'BOL' },
    { code: '+592', country: 'GUY' },
    { code: '+593', country: 'ECU' },
    { code: '+594', country: 'GUF' },
    { code: '+595', country: 'PRY' },
    { code: '+596', country: 'MTQ' },
    { code: '+597', country: 'SUR' },
    { code: '+598', country: 'URY' },
    { code: '+599', country: 'CUW' },
    { code: '+670', country: 'TLS' },
    { code: '+672', country: 'ATA' },
    { code: '+673', country: 'BRN' },
    { code: '+674', country: 'NRU' },
    { code: '+675', country: 'PNG' },
    { code: '+676', country: 'TON' },
    { code: '+677', country: 'SLB' },
    { code: '+678', country: 'VUT' },
    { code: '+679', country: 'FJI' },
    { code: '+680', country: 'PLW' },
    { code: '+681', country: 'WLF' },
    { code: '+682', country: 'COK' },
    { code: '+683', country: 'NIU' },
    { code: '+685', country: 'WSM' },
    { code: '+686', country: 'KIR' },
    { code: '+687', country: 'NCL' },
    { code: '+688', country: 'TUV' },
    { code: '+689', country: 'PYF' },
    { code: '+690', country: 'TKL' },
    { code: '+691', country: 'FSM' },
    { code: '+692', country: 'MHL' },
    { code: '+850', country: 'PRK' },
    { code: '+852', country: 'HKG' },
    { code: '+853', country: 'MAC' },
    { code: '+855', country: 'KHM' },
    { code: '+856', country: 'LAO' },
    { code: '+960', country: 'MDV' },
    { code: '+961', country: 'LBN' },
    { code: '+962', country: 'JOR' },
    { code: '+963', country: 'SYR' },
    { code: '+964', country: 'IRQ' },
    { code: '+965', country: 'KWT' },
    { code: '+967', country: 'YEM' },
    { code: '+968', country: 'OMN' },
    { code: '+970', country: 'PSE' },
    { code: '+973', country: 'BHR' },
    { code: '+975', country: 'BTN' },
    { code: '+976', country: 'MNG' },
    { code: '+977', country: 'NPL' },
    { code: '+992', country: 'TJK' },
    { code: '+993', country: 'TKM' },
    { code: '+994', country: 'AZE' },
    { code: '+995', country: 'GEO' },
    { code: '+996', country: 'KGZ' },
    { code: '+998', country: 'UZB' },
];

export default countryCodes;
