import React from 'react'

function About1() {
    return (
        <>
            <section class="bg-[#F8F9FF] py-12 px-6 sm:px-12 md:px-24 lg:px-36 text-gray-800">
                <div class="max-w-4xl mx-auto text-center">
                    <p class="text-lg text-gray-500 md:text-xl mb-4 leading-relaxed font-lexend">
                        In early 2022, Algoarts began as an ambitious idea, a seed planted by one curious student and five close friends at university. United by a shared passion for technology and innovation, they spent countless hours brainstorming, coding, and pushing each other to explore new boundaries in the digital world. What started as a student initiative evolved into a professional endeavor in May 2023, marking our mission to redefine digital possibilities.
                    </p>
                    <p class="text-lg md:text-xl mb-8 font-lexend text-gray-500 leading-relaxed">
                        Our mission is simple yet profound: <span class="text-blue-500 font-semibold italic">"Turning Ideas into Digital Reality."</span> Every project we take on, every solution we engineer, and every line of code we write is dedicated to this goal. Welcome to Algoarts—where dreams meet digital innovation.
                    </p>
                </div>
            </section>

            <section class="bg-[#F8F9FF] py-12 px-6 sm:px-12 md:px-24 lg:px-36  text-gray-500">
                <div class="max-w-4xl mx-auto">
                    <h3 class="text-2xl sm:text-3xl md:text-4xl font-bold font-poppins text-center mb-8 bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500">Our Journey</h3>
                    <div class="space-y-8">
                        <div class="flex flex-col sm:flex-row items-center text-center sm:text-left">
                            <div class="sm:w-1/3">
                                <p class="text-blue-500 font-bold font-poppins text-lg">Early 2022</p>
                            </div>
                            <div class="sm:w-2/3">
                                <p class="text-lg md:text-xl">
                                    The inception of Algoarts as a passion project among close friends with a shared love for technology and creativity.
                                </p>
                            </div>
                        </div>

                        <div class="flex flex-col sm:flex-row items-center text-center sm:text-left">
                            <div class="sm:w-1/3">
                                <p class="text-blue-500 font-bold font-poppins text-lg">May 17, 2023</p>
                            </div>
                            <div class="sm:w-2/3">
                                <p class="text-lg md:text-xl">
                                    Officially incorporated, transforming Algoarts into a professional venture committed to turning visions into digital realities.
                                </p>
                            </div>
                        </div>

                        <div class="flex flex-col sm:flex-row items-center text-center sm:text-left">
                            <div class="sm:w-1/3">
                                <p class="text-blue-500 font-bold font-poppins text-lg">Present</p>
                            </div>
                            <div class="sm:w-2/3">
                                <p class="text-lg md:text-xl">
                                    Growing steadily, pushing boundaries, and creating impactful digital solutions for businesses, creators, and entrepreneurs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About1
