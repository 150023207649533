import React from 'react'
import Navbar from '../navbar'
import Footer from '../footer'
import Inqure from './inqure'
import Ccform from './ccform'

function Contacts() {
    return (
        <>
            <Navbar />
            <Inqure />
            <Ccform />
            <Footer />
        </>
    )
}

export default Contacts