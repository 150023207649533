import React, { useState, useEffect } from 'react';
import { FaBlog, FaHome, FaMailBulk, FaUser } from "react-icons/fa";
import BlogForm from "../BlogForm";
import BlogTable from "./BlogTable";

function AdminBlog() {
    const [showTable, setShowTable] = useState(false);

    const handleToggleClick = () => {
        setShowTable(!showTable);
    };

    return (
        <div className="flex bg-gray-50" style={{ minHeight: '100vh' }}>
            {/* Sidebar */}
            <div className="w-16 md:w-60 bg-white shadow-md flex flex-col" style={{ minHeight: '100vh' }}>
                <div className="p-5 font-bold text-lg hidden md:block">Dashboard</div>
                <ul className="space-y-2 flex-grow">
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaHome className="text-xl" />
                        <span className="hidden md:inline">Home</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaUser className="text-xl" />
                        <span className="hidden md:inline">Profile</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaMailBulk className="text-xl" />
                        <span className="hidden md:inline">Messages</span>
                    </li>
                    <li className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200">
                        <FaBlog className="text-xl" />
                        <span className="hidden md:inline">Blog</span>
                    </li>
                </ul>
            </div>
            {/* Main Content */}
            <div className="flex-1 p-10 text-2xl font-bold">
                <button onClick={handleToggleClick} className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {showTable ? 'Add' : 'Manage'}
                </button>
                {showTable ? <BlogTable /> : <BlogForm />}
            </div>
        </div>
    );
}

export default AdminBlog;
