
import React from 'react'
import Navbar from '../components/navbar'
import Getintouch from '../components/getin_tuch'
import Footer from '../components/footer'
import Meet_web from './meeting api/meet_web'
import Auth from '../accets/auth.png'
import Payment from '../accets/internet.png'
import Transfer from '../accets/transfer.png'
import Bgimg from '../accets/bgimg.jpg'
import Team from '../accets/wct.jpg'
import Task from '../accets/task.jpg'

function Web_app_development() {
  return (
    <>
      <Navbar />
      {/* Section1 */}
      <div className="pt-18 pb-38 h-screen flex flex-col justify-center items-center bg-[#0f072c]">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
        <div
          className="relative text-center"
          data-aos="fade-up" // Animation type
          data-aos-duration="1000" // Duration of the animation
          data-aos-once="true" // Ensures animation occurs only once
        >
          <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
            Altitude - driven web solutions
          </p>
          <span className="text-3xl sm:text-6xl font-lucida font-bold text-white ">
            Gain a competitve edge with
            <br></br> <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500'>
              custom web application
            </span>
          </span>
          <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
            When spreadsheets fall short and off-the-shelf solutions no longer meet your needs,
            or when you're ready to bring a groundbreaking product or business idea to life,
            we're here to deliver tailored solutions. </p>
        </div>
      </div>
      {/* section1 */}


      <section>
        <div className="bg-[#F8F9FF] py-16  relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
            <div className="flex flex-wrap items-center justify-between -mx-4">
              <div className="w-full px-4 lg:w-6/12">
                <div className="flex items-center -mx-3 sm:-mx-4">
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="py-3 sm:py-4">
                      <img
                        src='https://illustrations.popsy.co/violet/keynote-presentation.svg'
                        alt="About Image 1"
                        className="w-full rounded-2xl"
                      />
                    </div>
                    <div className="py-3 sm:py-4">
                      <img
                        src='https://illustrations.popsy.co/violet/app-launch.svg'
                        alt="About Image 2"
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="relative z-10 my-4">
                      <img
                        src='https://illustrations.popsy.co/violet/man-riding-a-rocket.svg'
                        alt="About Image 3"
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                <div className="mt-10 lg:mt-0">
                  <span className="block mb-4 text-lg font-semibold text-green-500">
                    
                  </span>
                  <h2 className="mb-5 text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                    Optimized for your growth trajectory
                  </h2>
                  <p className="mb-5 font-lexend marquee-text text-gray-500 text-xl">
                    sing the advanced systems and frameworks of giants
                    (like Fortune technology companies)
                    we'll build an agile application that's ready
                    to scale with your growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className="py-16 relative" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/bgimg.jpg)`, // Check the path
          opacity: 0.9,
        }}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col-reverse md:flex-row items-center relative">
            {/* Text Section with drop shadow */}
            <div className="w-full md:w-1/2 text-center md:text-left bg-white p-8 rounded-sm shadow-lg relative z-10 mt-8 md:mt-0">
              <h2 className="text-2xl md:text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                Transform your workflows with systems that work together effortlessly.
              </h2>
              <p className="py-4 font-lexend marquee-text text-gray-500 text-xl">
                Throughout development, we'll link your application to all
                your existing systems, streamlining your process from multiple steps to just one.
              </p>
            </div>
            {/* Image Section */}
            <div className="w-full md:w-1/2 relative">
              <div className="relative -ml-0 md:-ml-10 lg:-ml-16">
                <img
                  src={Team}
                  alt="Olive Oil Products"
                  width={700}
                  height={450}
                  className="rounded-sm shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* headline */}
      <div className='bg-[#0f072c]'>
        <div className='pb-8 pt-8'>
          <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-center text-lg font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
            <div className="grid grid-cols-1 md:grid-cols-3 ml-16 mt-8" >
              <ul className="space-y-4 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                <div className="my-4 text-left text-gray-400"><img src={Auth} /></div>
                <li class="mt-4 mb-4 flex items-center text-gray-900 text-2xl sm:4xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                  User AUTH
                </li>

                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  OAuth
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  BioMatrix Authentication
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Federated Authentication
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Google Sign-in
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Facebook Login
                </li>

              </ul>
              <ul className="space-y-1 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                <div className="my-4 text-left text-gray-400"><img src={Payment} /></div>
                <li class=" mt-4 mb-4 flex items-center text-gray-900 text-2xl sm:4xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                  Online payments
                </li>

                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Payheare :<span className='text-sm'> only srilanka</span>
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Paypal :<span className='text-sm'>Selected reagions only</span>
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Payneeor
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Stripe
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Custom APIs
                </li>
              </ul>
              <ul className="space-y-4 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                <div className="my-4 text-left text-gray-400"><img src={Transfer} /></div>
                <li class=" mt-4 flex items-center text-gray-900 text-2xl sm:4xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                  Access external data
                </li>

                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Fullstacks
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  WordPress
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  3rd party databases
                </li>
                <li class="flex items-center">
                  <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Custom APIs
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* headline */}

      <section>
        <div className="bg-gray-120 py-16 relative" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/bgimg.jpg)`, // Check the path
          opacity: 0.9,
        }}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
            {/* Image Section */}
            <div className="w-full md:w-1/2 relative">
              <div className="relative md:-ml-10 lg:-ml-16">
                <img
                  src={Task}
                  alt="Olive Oil Products"
                  width={700}
                  height={450}
                  className="rounded-sm shadow-lg"
                />
              </div>
            </div>

            {/* Text Section - Mobile Card Style */}
            <div className="w-full md:w-1/2 text-center md:text-left bg-white p-8 rounded-sm shadow-lg relative z-10 mt-8 md:mt-0">
              <h2 className="text-2xl md:text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                Free up hundreds of hours by eliminating tedious manual tasks.
              </h2>
              <p className="py-4 font-lexend marquee-text text-gray-500 text-xl">
                Why manually build reports or input data when software can handle it for you?
                We eliminate your reliance on spreadsheets and binders full of business processes,
                freeing you to grow beyond the constraints of time-consuming systems.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* headline */}
      {/* headline */}
      <section>
        <div className="bg-[#F8F9FF] py-16  relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
            <div className="flex flex-wrap items-center justify-between -mx-4">
              <div className="w-full px-4 lg:w-6/12">
                <div className="flex items-center -mx-3 sm:-mx-4">
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="py-3 sm:py-4">
                      <img
                        src='https://illustrations.popsy.co/violet/team-idea.svg'
                        alt="About Image 1"
                        className="w-full rounded-2xl"
                      />
                    </div>
                    <div className="py-3 sm:py-4">
                      <img
                        src='https://illustrations.popsy.co/violet/freelancer.svg'
                        alt="About Image 2"
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="relative z-10 my-4">
                      <img
                        src='https://illustrations.popsy.co/violet/digital-nomad.svg'
                        alt="About Image 3"
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                <div className="mt-10 lg:mt-0">
                  <span className="block mb-4 text-lg font-semibold text-green-500">
                    
                  </span>
                  <h2 className="mb-5 text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                    Banish mistakes and slip-ups
                  </h2>
                  <p className="mb-5 font-lexend marquee-text text-gray-500 text-xl">
                    Your processes are effective until a step is missed. We design systems to eliminate human error in your workflows, freeing you from relying on memory to ensure tasks are completed accurately.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Meet_web />
      <Getintouch />
      <Footer />
    </>
  )
}

export default Web_app_development



