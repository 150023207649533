import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import './../components/css/button.css'


export default function Call_modal() {
    useEffect(() => {
        (async function () {
            try {
                console.log("Initializing Cal API...");
                const cal = await getCalApi();
                console.log("Cal API initialized", cal);

                cal("ui", {
                    styles: {
                        branding: { brandColor: "#000000" }
                    },
                    hideEventTypeDetails: false,
                    layout: "month_view"
                });

                console.log("Cal API UI configured");
            } catch (error) {
                console.error("Failed to initialize Cal API", error);
            }
        })();
    }, []);

    return (



        <button
            data-cal-namespace=""
            data-cal-link="algoarts/30min"
            data-cal-config='{"layout":"month_view"}'
            class=" pt-16 text-2xl hover text-sky-400 after:content-['_↗'] ..." >Book your slot Now!</button>


    );
}
