import React, { useState, useRef, useEffect } from 'react';
import Experets from './widgets/tech_logostrip';
import '../components/css/heroscreen.css';

function Hero() {

  return (
    // <div ref={myRef} className="h-screen flex flex-col justify-center items-center">
    <div className="pt-64 pb-16 h-screen flex flex-col justify-center items-center bg-[#0f072c]">
      <div className="text-center">
        <div
          className="relative text-center"
          data-aos="fade-up" // Animation type
          data-aos-duration="1000" // Duration of the animation
          data-aos-once="true" // Ensures animation occurs only once
        >
          <div>
            <span className="text-4xl sm:text-7xl font-lucida  font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:from-pink-500 hover:to-yellow-500">
              Revolutionize
              <span className="text-2xl sm:text-5xl font-lucida font-bold text-white"> Your Business</span>
            </span>
            <br></br>
           
          </div>
          <div className="mt-1">
            <span className="text-2xl sm:text-5xl font-lucida font-bold text-white">With Our</span>
            <span className="text-2xl sm:text-5xl font-lucida font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400  "> Digital Solutions</span>
          </div>
          <div className="py-8 absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto">
          </div >
          <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
            We excel in converting your innovative ideas into robust digital solutions.
            By developing tailored products for your market, we help you enhance customer value,
            unlock new revenue opportunities, and boost business agility.
          </p>
          <div className="mt-8 max-w-sm sm:max-w-3xl mx-auto">
            <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
              Experts In
            </p>
            <Experets />
          </div>
        </div>
      </div>
    </div >
  );
}

export default Hero;
