// SplashScreen.js
import React, { useState, useEffect } from 'react';
import './css/splashscreen.css';

const SplashScreen = () => {

    const text = "Algoarts";
    const [displayedText, setDisplayedText] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setDisplayedText(displayedText + text[index]);
                setIndex(index + 1);
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [index, displayedText, text]);

    return (
        <div className="splash-screen">
            <h1 className="mt-6 text-8xl leading-8 text-gray-700 font-poppins typing-animation">
                {displayedText}
            </h1>
        </div>
    );
};

export default SplashScreen;
