import React, { useState } from 'react'
import './css/content.css'
import Team from '../accets/wct.jpg'


function Content() {

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (

    <section>
      <div>
        <div className="bg-white" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/../bgimg.jpg)`,
          opacity: 0.9,
        }}>
          <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="relative isolate overflow-hidden bg-gray-200 px-6 pt-16 shadow-xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              >
                <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                    <stop stopColor="#FF69B4" />  {/* A bright pink (HotPink) */}
                    <stop offset={1} stopColor="#FFC0CB" />  {/* A lighter pink (LightPink) */}
                  </radialGradient>
                </defs>


              </svg>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">

                <p className="text-2xl font-bold text-pink-500 mb-2">Problem Identification</p>

                <h2 className="text-3xl font-bold tracking-tight bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                  Pinpointing Issues for Effective Solutions
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-500">

                  Pinpointing Issues for EffectBuilding tech and digital products can appear straightforward,
                  yet ensuring they resonate with a specific business domain and meet market demands requires a deeper
                  understanding. Rushing into development without this insight often leads to products that fall short of
                  expectations, failing to address customer needs effectively. This approach not only wastes resources but
                  also risks damaging reputation and losing market opportunities.


                  {!isExpanded && (
                    <button
                      onClick={toggleExpand}
                      className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4"
                    >
                      Read more &gt;&gt;
                    </button>
                  )}
                </p>
                {isExpanded && (
                  <div className="mt-4">
                    <p className="mt-6 text-lg leading-8 text-gray-500">
                      Therefore, prioritizing comprehensive
                      market research and understanding business requirements from the outset is essential for creating successful,
                      customer-centric products that deliver real value and foster long-term success.ive Solutions.
                    </p>
                    <button
                      onClick={toggleExpand}
                      className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4"
                    >
                      Hide &gt;&gt;
                    </button>
                  </div>
                )}
              </div>
              <div className="relative mt-16 h-80 lg:mt-8">
                <img
                  alt="App screenshot"
                  src="https://illustrations.popsy.co/violet/business-presentation.svg"
                  width={1824}
                  height={1080}
                  className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
