import React, { useState } from 'react';

const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <button
                className="flex justify-between items-center w-full p-2 text-left text-gray-900"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-gray-900 text-base text-xl">{title}</span>
                <svg
                    className={`w-4 h-4 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            {isOpen && <div className="p-2">{children}</div>}
        </div>

    );
};

const Accordion = () => {
    return (
        <div className="mx-16  sm:mx-32 sm:ps-4 marquee-text font-lucida">
            <div className="grid grid-cols-1  md:grid-cols-2 ml-16 mt-8" >
                <ul className="space-y-1 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Personalized Experiences">
                                <ul>
                                    <li className="flex items-center">
                                        We understand that every customer is unique. By utilizing data-driven insights, we tailor our approach to meet the specific needs and preferences of each client. This ensures a more personalized and effective engagement, building stronger connections and trust.
                                    </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Omnichannel Communication">
                                <ul>
                                    <li className="flex items-center">
                                        Engaging with customers where they are is crucial. We provide seamless communication across various channels, including social media, email, chat, and phone. This omnichannel approach ensures that our clients can reach us easily and conveniently, enhancing their overall experience.                                </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Proactive Support">
                                <ul>
                                    <li className="flex items-center">
                                        Anticipating our customers' needs is key to maintaining satisfaction and loyalty.
                                        Our proactive support system identifies potential issues before they arise and offers
                                        timely solutions. This not only resolves problems quickly but also demonstrates our
                                        commitment to exceptional service.
                                    </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Feedback and Improvement">
                                <ul>
                                    <li className="flex items-center">
                                        Customer feedback is invaluable. We actively seek and incorporate feedback to continuously improve our services and products. By listening to our customers, we ensure that we evolve in ways that best serve their needs.                                </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                </ul>
                <ul className="space-y-1 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Community Engagement">
                                <ul>
                                    <li className="flex items-center">
                                        We believe in giving back to the community. Through various initiatives and partnerships, we engage with the community to create a positive impact. This not only strengthens our brand but also builds a sense of trust and loyalty among our customers.                                </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Innovative Solutions">
                                <ul>
                                    <li className="flex items-center">
                                        Innovation is at the heart of what we do. Our team continuously explores new technologies and methods to enhance customer engagement. From AI-driven analytics to advanced CRM systems, we implement innovative solutions that keep us ahead of the curve.                                </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Case Studies">
                                <ul>
                                    <li className="flex items-center">
                                        Explore our success stories to see how we’ve helped businesses like yours achieve remarkable customer engagement results. Our case studies showcase the tangible benefits of our strategies and the positive impact they’ve had on our clients.                                </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                    <li class="flex items-center">
                        <div className="w-full max-w-md mx-auto">
                            <AccordionItem title="Data-Driven Insights">
                                <ul>
                                    <li className="flex items-center">
                                        We harness the power of data to understand and anticipate customer needs. By analyzing behavioral patterns and feedback, we make informed decisions that drive engagement strategies and improve overall customer satisfaction.                                    </li>
                                </ul>
                            </AccordionItem>
                            {/* Add more AccordionItem components here as needed */}
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    );
};

export default Accordion;
