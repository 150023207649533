import React from 'react'
import StepperForm from '../stepfourm';
import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

function Meet_software() {
    useEffect(() => {
        (async function () {
            const cal = await getCalApi({ "namespace": "meet-software-development-expert" });
            cal("ui", { "styles": { "branding": { "brandColor": "#000000" } }, "hideEventTypeDetails": false, "layout": "month_view" });
        })();
    }, [])

    return (
        <div data-aos="fade-down">
            <div className="bg-[#F8F9FF] min-h-screen relative">
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >See if we're a good fit<br></br></span>
                    {/* <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >you can solve virtually any business problem</span> */}
                </div>
                <div className="marquee-text text-center text-xl sm:text-2xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <p className="py-4 text-gray-500 font-lexend max-w-2xl mx-auto">
                        Have a hunch that we might be your type? Tell us a little about your project. You'll hear back from us within one business day (but probably sooner).
                    </p>
                </div>
                <div className="text-center">
                    <button
                        className="marquee-text text-xl sm:text-xl font-lucida bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r"
                        data-cal-link="algoarts/meet-software-development-expert"
                        data-cal-config='{"layout":"month_view"}'
                    >
                        Contact Our Expert
                    </button>
                </div>

                <div class="relative py-4">
                    <div class="flex items-center justify-center">
                        <div class="h-0.5 bg-gradient-to-r from-purple-400 to-transparent w-1/4"></div>
                        <div className='text-xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r'>OR</div>
                        <div class="h-0.5 bg-gradient-to-l from-purple-400 to-transparent w-1/4"></div>
                    </div>
                </div>
                {/* headline */}
                {/* devider */}
                {/* contactform */}
                <div className="marquee-text text-center text-xl sm:text-xl font-lucida  bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Submit Your Inquery<br></br></span>
                    {/* <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >you can solve virtually any business problem</span> */}
                </div>
                <div className="mt-2  flex items-center justify-center ">
                    <StepperForm />
                </div>
            </div>
        </div>

    )
}

export default Meet_software