import React from 'react'
import Navbar from '../components/navbar'
import Getintouch from '../components/getin_tuch'
import Footer from '../components/footer'
import Meet_product from './meeting api/meet_product'
import ProjectManagement from '../accets/product.jpg'
import Solution from '../accets/solution.jpg'
import vision from '../accets/target.png'
import Trade from '../accets/trade.png'
import Share from '../accets/share.png'
import Skill from '../accets/skill.png'
import Analysis from '../accets/analysis.png'
import Advise from '../accets/guide.png'
import Team from '../accets/wct.jpg'


function Product_management() {
    return (
        <>
            <Navbar />
            {/* Section1 */}
            <div className="pt-18 pb-38  h-screen flex flex-col justify-center items-center bg-[#0f072c]">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
                        Altitude - driven project management
                    </p>
                    <span className="text-3xl sm:text-6xl font-lucida font-bold text-white ">
                        Gain a competitve
                        edge with<br></br> <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500'>
                            project management
                        </span>
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        Efficiently planning, organizing, and executing projects to achieve business goals,
                        milestones, and deliverables within time and budget constraints.
                    </p>
                </div>
            </div>
            {/* Section */}

            <section>
                <div className="bg-[#F8F9FF] py-16  relative">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
                        <div className="flex flex-wrap items-center justify-between -mx-4">
                            <div className="w-full px-4 lg:w-6/12">
                                <div className="flex items-center -mx-3 sm:-mx-4">
                                    <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                        <div className="py-3 sm:py-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/graphic-design.svg'
                                                alt="About Image 1"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                        <div className="py-3 sm:py-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/designer.svg'
                                                alt="About Image 2"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                        <div className="relative z-10 my-4">
                                            <img
                                                src='https://illustrations.popsy.co/violet/creative-work.svg'
                                                alt="About Image 3"
                                                className="w-full rounded-2xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                                <div className="mt-10 lg:mt-0">
                                    <span className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r uppercase font-semibold font-lucida">
                                        Product Harmony
                                    </span>
                                    <h2 className="mb-5 text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                                        Facing challenges with a unified product vision in your team?
                                    </h2>
                                    <p className="mb-5 font-lexend marquee-text text-gray-500 text-xl">
                                        If fragmented strategies and disjointed innovation are issues, see how Beta Launch's 'Digital-Product-Managers-as-a-Service' can align your team and create a unified path to success.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="py-16 relative" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/bgimg.jpg)`, // Check the path
                    opacity: 0.9,
                }}>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col-reverse md:flex-row items-center relative">
                        {/* Text Section with drop shadow */}
                        <div className="w-full md:w-1/2 text-center md:text-left bg-white p-8 rounded-sm shadow-lg relative z-10 mt-8 md:mt-0">
                            <div className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r uppercase font-semibold font-lucida "
                            >Major Roadblocks</div>

                            <h2 className="text-2xl md:text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                                Are These Challenges Impacting Your Digital Product Teams?
                            </h2>
                            <p className="py-4 font-lexend marquee-text text-gray-500 text-xl">
                                In the fast-evolving world of digital product development, challenges such as disjointed efforts and vague product strategies can hinder your startup's path to success.
                            </p>
                        </div>
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 relative">
                            <div className="relative -ml-0 md:-ml-10 lg:-ml-16">
                                <img
                                    src={Team}
                                    alt="Olive Oil Products"
                                    width={700}
                                    height={450}
                                    className="rounded-sm shadow-lg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='bg-[#0f072c] py-16 relative'>
                <div className="flex flex-col md:flex-row gap-8 justify-center text-center max-w-6xl mx-auto">
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={vision} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Facing Challenges in Unifying Your Product Vision?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight mt-9 text-left">
                            Fostering a unified product vision and ensuring alignment within your product team can be challenging.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Trade} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Finding the Demand for Product Management Expertise Overwhelming?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-left">
                            The demand for product management expertise can be overwhelming, and hiring full-time staff may not be cost-effective.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Share} /></div>
                        <div className="my-4"></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Experiencing Fragmentation in Your Product Development Process?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-gray-500 text-left">
                            Fragmented product development efforts can lead to poor outcomes and innovation roadblocks.
                        </div>
                    </div>
                </div>
            </div>


            <section>
                <div className="bg-gray-120 py-16 relative" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/bgimg.jpg)`, // Check the path
                    opacity: 0.9,
                }}>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center relative">
                        {/* Image Section */}
                        <div className="w-full md:w-1/2 relative">
                            <div className="relative md:-ml-10 lg:-ml-16">
                                <img
                                    src={Team}
                                    alt="Olive Oil Products"
                                    width={700}
                                    height={450}
                                    className="rounded-sm shadow-lg"
                                />
                            </div>
                        </div>
                        {/* Text Section - Mobile Card Style */}
                        <div className="w-full md:w-1/2 text-center md:text-left bg-white p-8 rounded-sm shadow-lg relative z-10 mt-8 md:mt-0">
                            <div className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r uppercase font-semibold font-lucida "
                            >What We Offer
                            </div>
                            <h2 className="text-2xl md:text-3xl text-lxend font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r mb-4">
                                On-Demand Product Managers
                            </h2>
                            <p className="py-4 font-lexend marquee-text text-gray-500 text-xl">
                                Our 'On-Demand Product Managers' service enhances your product journey by providing
                                experienced Digital Product Managers who align your vision with flawless execution,
                                ensuring a smooth, market-focused process.
                            </p>
                        </div>
                    </div>
                </div>
            </section>





            <div className='bg-[#0f072c] py-16 relative'>
                <div className="flex flex-col md:flex-row gap-8 justify-center text-center max-w-6xl mx-auto">
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-1 text-left text-gray-400"><img src={Skill} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Skilled Digital Product Managers
                        </h5>
                        <div className="my-6"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight mt-9 text-left">
                            Engage with experienced Digital Product Managers who are committed to the success of your project                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-2 text-left text-gray-400"><img src={Analysis} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            All-Encompassing Market and Segment Analysis
                        </h5>
                        <div className="my-4"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-left">
                            We begin by performing in-depth market research and targeting the optimal
                            customer segment to align your product with customer needs.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-2 text-left text-gray-400"><img src={Advise} /></div>
                        <div className="my-1"></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Product Strategy Advisory
                        </h5>
                        <div className="my-8"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-gray-500 text-left">
                            Leverage expert guidance, strategic planning, and best practices
                            to create innovative products and achieve successful launches.
                        </div>
                    </div>
                </div>
            </div>
            {/* end */}
            {/* end */}
            <div className='pb-16 pt-16 bg-[#F8F9FF] min-h-screen relative'>
                <Meet_product />
            </div>
            <Getintouch />
            <Footer />
        </>
    )
}

export default Product_management