// import React, { useState } from 'react';
//
// function BlogForm() {
//     const [formData, setFormData] = useState({
//         tag: '',
//         heading: '',
//         description: '',
//         author: '',
//         designation: '',
//         imageUrl: '',
//         authorImageUrl: '/image.png'
//     });
//
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prev => ({
//             ...prev,
//             [name]: value
//         }));
//     };
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const response = await fetch('https://chethakalakshitha.xyz/algoarts/backend/blogs', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 ...formData,
//                 createdAt: new Date() // Send the current date/time as part of the form data
//             })
//         });
//
//         const data = await response.json();
//         console.log("Server Response:", data);
//         if (response.ok) {
//             alert('Blog posted successfully!');
//         } else {
//             alert('Failed to post blog. Please check the console for more info.');
//         }
//     };
//
//     return (
//         <div className="flex justify-center items-center h-full">
//             <form className="bg-white p-6 rounded shadow-md w-full max-w-lg" onSubmit={handleSubmit}>
//                 <h2 className="text-lg font-bold mb-4">Submit Blog Details</h2>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Tag</label>
//                     <input type="text" name="tag" value={formData.tag} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Heading</label>
//                     <input type="text" name="heading" value={formData.heading} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Description</label>
//                     <textarea name="description" value={formData.description} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Author</label>
//                     <input type="text" name="author" value={formData.author} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Designation</label>
//                     <input type="text" name="designation" value={formData.designation} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Image URL</label>
//                     <input type="text" name="imageUrl" value={formData.imageUrl} onChange={handleChange} className="mt-1 p-2 border rounded w-full" />
//                 </div>
//                 <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//                     Submit
//                 </button>
//             </form>
//         </div>
//     );
// }
//
// export default BlogForm;


import React, { useState } from 'react';

function BlogForm() {
    const [formData, setFormData] = useState({
        tag: '',
        heading: '',
        content: [],
        author: '',
        designation: ''
    });

    const handleContentChange = (index, field, value) => {
        const updatedContent = formData.content.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setFormData({ ...formData, content: updatedContent });
    };

    const addContentItem = (type) => {
        setFormData({
            ...formData,
            content: [...formData.content, { type: type, content: '', order: formData.content.length + 1 }]
        });
    };

    const removeContentItem = (index) => {
        setFormData({
            ...formData,
            content: formData.content.filter((item, i) => i !== index)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure the order is set correctly for each content item
        const orderedContent = formData.content.map((item, index) => ({
            ...item,
            order: index + 1 // Ensure order starts from 1 and is sequential
        }));

        // Prepare the complete blog post data
        const completeFormData = {
            tag: formData.tag,
            heading: formData.heading,
            content: orderedContent,
            author: formData.author,
            designation: formData.designation
        };

        try {
            // Send the blog post data to the server via POST request
            const response = await fetch('https://chethakalakshitha.xyz/algoarts/backend/blogs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(completeFormData)
            });

            const data = await response.json(); // Assuming the server sends back JSON

            if (response.ok) {
                console.log("Blog created successfully:", data);
                alert('Blog posted successfully!');
                // Optionally clear the form or handle navigation after successful posting
                setFormData({
                    tag: '',
                    heading: '',
                    content: [],
                    author: '',
                    designation: ''
                });
            } else {
                console.error("Failed to submit blog:", data);
                alert('Failed to post blog. Please check the console for more info.');
            }
        } catch (error) {
            console.error('Error submitting blog:', error);
            alert('An error occurred while submitting the blog. Please try again.');
        }
    };


    return (
        <div className="flex justify-center items-center h-full">
            <form className="bg-white p-6 rounded shadow-md max-w-4xl w-full space-y-4" onSubmit={handleSubmit}>
                <h2 className="text-lg font-bold mb-4">Submit Blog Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <input type="text" name="tag" value={formData.tag} onChange={(e) => setFormData({...formData, tag: e.target.value})} placeholder="Tag" className="mt-1 p-2 border rounded w-full" />
                    <input type="text" name="heading" value={formData.heading} onChange={(e) => setFormData({...formData, heading: e.target.value})} placeholder="Heading" className="mt-1 p-2 border rounded w-full" />
                    <input type="text" name="author" value={formData.author} onChange={(e) => setFormData({...formData, author: e.target.value})} placeholder="Author" className="mt-1 p-2 border rounded w-full" />
                    <input type="text" name="designation" value={formData.designation} onChange={(e) => setFormData({...formData, designation: e.target.value})} placeholder="Designation" className="mt-1 p-2 border rounded w-full" />
                </div>
                {formData.content.map((item, index) => (
                    <div key={index} className="flex flex-wrap items-center gap-2">
                        {item.type === 'paragraph' || item.type === 'heading' || item.type === 'subheading' ? (
                            <textarea name="content" value={item.content} onChange={(e) => handleContentChange(index, 'content', e.target.value)} className="mt-1 p-2 border rounded w-full" rows="2" />
                        ) : (
                            <input type="text" name="content" value={item.content} onChange={(e) => handleContentChange(index, 'content', e.target.value)} className="mt-1 p-2 border rounded w-full" />
                        )}
                        <button type="button" onClick={() => removeContentItem(index)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Remove</button>
                    </div>
                ))}
                <div className="flex gap-2 justify-center flex-wrap">
                    <button type="button" onClick={() => addContentItem('paragraph')} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Paragraph</button>
                    <button type="button" onClick={() => addContentItem('image')} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Add Image</button>
                    <button type="button" onClick={() => addContentItem('heading')} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Add Heading</button>
                    <button type="button" onClick={() => addContentItem('subheading')} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Add Subheading</button>
                </div>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">Submit</button>
            </form>
        </div>
    );
}

export default BlogForm;
