import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Navigation, Autoplay } from 'swiper/modules';

function Reviews() {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get('https://chethakalakshitha.xyz/algoarts/backend/review/reviews');
                setReviews(response.data);
            } catch (error) {
                console.error('Error fetching reviews', error);
            }
        };
        fetchReviews();
    }, []);

    return (

        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            className="mySwiper"
            modules={[Navigation, Autoplay]}
        >
            {reviews.map((review) => (
                <SwiperSlide key={review._id}>
                    <div class="flex justify-center items-center lg:px-10 mt-20 px-4">
                        <div class="w-full  lg:w-3/4 hover:shadow-indigo-300 hover:shadow-lg rounded-lg border">
                            <div class="flex justify-center items-start flex-col p-5 ">

                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" stroke="currentColor"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    class="icon icon-tabler icon-tabler-quote rotate-180 text-sky-500" viewBox="0 0 24 24">
                                    <path stroke="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        d="M10 11H6a1 1 0 01-1-1V7a1 1 0 011-1h3a1 1 0 011 1v6c0 2.667-1.333 4.333-4 5M19 11h-4a1 1 0 01-1-1V7a1 1 0 011-1h3a1 1 0 011 1v6c0 2.667-1.333 4.333-4 5">
                                    </path>
                                </svg>

                                <div class="flex justify-center items-start flex-col text-left gap-5">
                                    <p class="italic text-sm md:text-base">
                                        {review.description}
                                    </p>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {Array.from({ length: review.stars }, (_, index) => (
                                            <svg key={index} className="w-5 h-5" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z" fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <div>
                                        <h3 class="text-xl md:text-2xl font-semibold">{review.name}</h3>
                                        <p class="text-xs md:text-sm">{review.designation}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="bg-sky-500 p-0.5 rounded-b-lg"></div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default Reviews;
