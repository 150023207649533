import React from 'react';
import {
    ArrowPathRoundedSquareIcon,
    CogIcon,
    UsersIcon
} from '@heroicons/react/24/outline';

function Inherit() {
    const cards = [
        {
            id: 1,
            title: 'Enterprise Resource Planning.',
            description: 'Manage and optimize your core business processes to reduce manual tasks, minimize errors, and improve efficiency.',
            icon: ArrowPathRoundedSquareIcon
        },
        {
            id: 2,
            title: 'B2B and B2C Web Portals.',
            description: 'Create a communication system that meets the needs of your business, with personalized areas for both employees and customers.',
            icon: CogIcon,
        },
        {
            id: 3,
            title: 'Entriprise Portals & Intranets.',
            description: 'Set up a system with protected staff, member, or customer areas that allow secure access to information or admin interfaces.',
            icon: UsersIcon,
        },
    ];

    return (



        <div className="mx-4 pt-8 sm:mx-8 sm:ps-24 marquee-text text-lg font-lucida text-gray-800 sm:text-xl pb-16">
            <div className="carousel-container overflow-hidden relative w-full h-full">
                <div className="flex transition-transform duration-300 ease-in-out overflow-x-auto sm:overflow-hidden">
                    {cards.map((card) => (
                        <div data-aos="flip-left">
                            <div
                                key={card.id}
                                className="card bg-white shadow-lg rounded-lg p-6 m-4 w-80 flex-none relative overflow-hidden"
                                style={{ height: '26rem' }}
                            >
                                {/* Background image and opacity effect using a pseudo-element */}
                                <div
                                    className="absolute inset-0 bg-cover bg-center"
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/../bg.jpg)`,
                                        opacity: 0.1,
                                    }}
                                ></div>
                                <div className="relative z-10"> {/* Ensure the content is above the background */}
                                    <div className="pt-18 flex h-16 w-16 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white md:pt-18 md:flex md:h-16 md:w-16">
                                        {card.icon && <card.icon className="text-gray-600 group-hover:text-indigo-600 " aria-hidden="true" />}
                                    </div>
                                    <div className="pt-8 mt-18n font-poppins text-3xl sm:text-stroke text-black">
                                        {card.title}
                                    </div>
                                    <h2 className="pb-16 pt-8 font-lexend text-xl mb-22 sm:text-lg flex items-center">
                                        {card.description}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>

    );
}

export default Inherit;
