import React from 'react'
import Navbar from '../navbar'
import Getintouch from '../getin_tuch'
import Footer from '../footer'

function Operational_teams() {
    return (
        <>
            <Navbar />
            <Getintouch />
            <Footer />
        </>
    )
}

export default Operational_teams