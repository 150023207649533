import React from 'react'
import Navbar from '../navbar'
import Getintouch from '../getin_tuch'
import Footer from '../footer'
import '../css/animation.css'
import '../css/csengage.css'
import Accordion from '../../services/accordian'
import Contactuslionk from '../../services/contactuslionk'


function Customer_engagement() {
    return (
        <>
            <Navbar />
            {/* section1 */}
            <div className="mt-16 relative py-32 flex flex-col justify-center items-center bg-[#0f072c] overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <span className="text-4xl sm:text-7xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500">
                        Building Strong Relationships <br />Through Innovation...
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        we believe that customer engagement is the cornerstone of business success. Our dedicated team leverages cutting-edge technology and personalized strategies to foster meaningful interactions with our clients.
                    </p>
                </div>
            </div>
            {/* section1 */}
            {/* section2 */}
            {/* <div className="min-h-screen py-8 relative bg-cover bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/../01bg.jpg)`, opacity: 10 }}>
                <div className="flex flex-col lg:flex-row justify-between mx-4 sm:mx-8 sm:ps-28">
                    <div className="lg:w-3/5">
                        <div className="pb-4 py-16 font-light text-gray-500 sm:text-lg dark:text-gray-400">
                            <div className="py-16 text-3xl font-bold font-lucida tracking-tight text-pink-500 sm:text-2xl">
                                <p className="font-lucida">We didn't reinvent the wheel</p>
                            </div>
                            <p className="mb-4 text-2xl font-lucida tracking-tight text-white py-8 pr-8 pl-8 bg-[#0f072c] opacity-70">
                                We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need.<br></br>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* section2 */}
            {/* section3 */}
            <div className="bg-[#F8F9FF] min-h-screen py-6 relative">
                <div data-aos="fade-down" className='mb-16'>
                    <div className="md mx-4 sm:mx-8 sm:ps-28 mt-16 marquee-text text-left text-2xl sm:text-3xl sm:text-left font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >We didn't reinvent the wheel<br></br></span>
                        {/* <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >systems that work together effortlessly.</span> */}
                    </div>
                    <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-left text-xl w-3/6 sm:text-2xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <p className="py-4 font-lexend text-gray-500">We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need.<br></br>
                        </p>
                    </div>
                </div>
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Here's how we make it happen<br></br></span>
                </div>
                {/* devider */}
                <div class="relative py-4">
                    <div class="flex items-center justify-center">
                        <div class="h-0.5 bg-gradient-to-r from-purple-400 to-transparent w-1/4"></div>
                        <div class="h-0.5 bg-gradient-to-l from-purple-400 to-transparent w-1/4"></div>
                    </div>
                </div>
                {/* devider */}
                {/* checklist */}
                <Accordion />
                {/* checklist */}
                {/* section3 */}
                <div data-aos="fade-down" className='<div class="md:flex">'>
                    <div className="md mx-4 sm:mx-8 sm:ps-28 mt-16 marquee-text text-left text-2xl sm:text-3xl sm:text-left font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Our Approach<br></br></span>
                    </div>
                    <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-left text-xl w-3/6 sm:text-2xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <p className="py-4 font-lexend text-gray-500">"Connecting Innovation with Every Interaction"</p>
                    </div>
                    {/* gggg */}
                    <div className="mx-4 mt-8 sm:mx-8 sm:ps-28 marquee-text text-left text-xl w-5/6 sm:text-2xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <div class="grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2 bg-white dark:bg-gray-800">
                            <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
                                <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Personalized Communication</h3>
                                    <p class="my-4">We tailor our communication strategies to match the preferences and needs of each customer, ensuring they feel valued and understood.</p>
                                </blockquote>
                            </figure>
                            <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 md:rounded-se-lg dark:bg-gray-800 dark:border-gray-700">
                                <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Feedback-Driven Innovation</h3>
                                    <p class="my-4">We actively seek and incorporate customer feedback to continuously improve our products and services.</p>
                                </blockquote>
                            </figure>
                            <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 md:rounded-es-lg md:border-b-0 md:border-e dark:bg-gray-800 dark:border-gray-700">
                                <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Proactive Support</h3>
                                    <p class="my-4">Our support team is always one step ahead, anticipating issues and offering solutions before problems arise.</p>
                                </blockquote>
                            </figure>
                            <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-gray-200 rounded-b-lg md:rounded-se-lg dark:bg-gray-800 dark:border-gray-700">
                                <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Community Building</h3>
                                    <p class="my-4">We foster a community of users who can share insights, offer support, and drive innovation together.</p>
                                </blockquote>
                            </figure>
                        </div>
                    </div>
                    {/* gggg */}
                    <Contactuslionk />
                </div>
                {/* section3 */}
            </div>
            <Getintouch />
            <Footer />
        </>
    )
}

export default Customer_engagement