import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../components/css/animation.css';


const InstagramPost = () => {
  const [post, setPost] = useState(null);
  const accessToken = 'IGQWRQM01VUjFoT2xJTDl2WExka2YyZAlYxTjViODlld09ySzFuV0dlWmZA0RTdqYnJEQWR0c1hDMzA2Y2dSZAjNHZA0IxcmF3M1Y4MFczcWRDNlVqbENhVFBYMWNnTDZAPeFhzMzRJWnMzS3RSd3hYaEpRMm9MUkhwOHcZD'; // Replace with your Instagram access token
  const userId = 'algoarts.studio'; // Replace with your Instagram user I
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const response = await axios.get('https://graph.instagram.com/me/media', {
          params: {
            fields: 'id,caption,media_url,permalink',
            access_token: accessToken,
          },
        });
        // Assuming you want to display the latest three posts
        const latestPosts = response.data.data.slice(0, 3);
        setPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchInstagramPosts();
  }, [accessToken]);

  if (posts.length === 0) {
    return <div>Loading...</div>;
  }

  //sliding

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //sliding

  return (


    <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
      <div className="py-8 text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
        <p className="font-lexend mx-4 mt-8 sm:mx-8 sm:ps-28 py-4">Our Stories</p>
      </div>

      {/* <div className="mx-4 sm:mx-8 sm:ps-28 text-justify max-w-5xl marquee-text text-1xl whitespace-pre-line font-poppins text-gray-600 hover:text-pink inline-block">
        Explore "Our Stories," a heartfelt collection of memories, achievements, and milestones from our company family.
        From the moments that shaped us to the triumphs that define our path forward, each story reflects our dedication,
        passion, and unwavering spirit. Join us as we celebrate our journey together, weaving the fabric of our shared
        experiences into the vibrant tapestry of our company's legacy.</div> */}

      <div className="mx-4 pt-8 sm:mx-9 sm:ps-24 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16">
        <div className="carousel-container overflow-hidden relative w-full h-full">
          <div className="flex transition-transform duration-300 ease-in-out overflow-x-auto sm:overflow-hidden">
            <div className="flex flex-nowrap gap-8">
              {posts.map((post) => (
                <div
                  key={post.id}
                  className="relative w-64 h-80 bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:shadow-inner-glow hover:scale-105 mb-8"
                >
                  <img
                    className="w-full h-full object-cover"
                    src={post.media_url}
                    alt={post.title}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end justify-center opacity-0 transition-opacity duration-500 hover:opacity-100">
                    <div className="p-4 text-white text-left w-full bg-black bg-opacity-70">
                      <h3 className="text-lg font-bold">{post.title}</h3>
                      <p className="text-lg font-poppins">{post.caption}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Button */}

      <div className="hidden sm:block absolute top-2/3 right-24 mx-8 sm:mx-4">
        <a href="/academy/blog" className="text-2xl hover:text-sky-400 after:content-['_↗']">
          View more Stories
        </a>
      </div>

      <div className="block sm:hidden mx-4 sm:mx-8 sm:ps-16 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16">
        <div className="text-center">
          <button href="/academy/blog" className="text-2xl hover:text-sky-400 after:content-['_↗'] ...">
            View more Stories
          </button>
        </div>
      </div>
    </div>
    
    // <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
    //   <div className="text-sm font-bold font-poppins tracking-tight text-pink-500 sm:text-xl">
    //     <p className="font-lexend mx-4 mt-8 sm:mx-8 sm:ps-28 py-4">Our Stories</p>
    //   </div>
    //   <div className="mx-4 sm:mx-8 sm:ps-28 text-justify max-w-5xl marquee-text text-lg font-poppins text-gray600 hover:text-pink inline-block sm:text-3xl">
    //     Our dedicated services enable you to confidently advance through your product journey, choosing the precise solutions required at each step.
    //   </div>
    //   <div className="mx-4 pt-8 sm:mx-8 sm:ps-24 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16">
    //     <div className="carousel-container overflow-hidden relative w-full h-full">
    //       <div className="flex transition-transform duration-300 ease-in-out overflow-x-auto sm:overflow-hidden">
    //         <div className="flex flex-nowrap gap-8">
    //           {posts.map((post) => (
    //             <div
    //               key={post.id}
    //               className="relative w-64 h-80 bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:shadow-inner-glow hover:scale-105 mb-8"
    //             >
    //               <img
    //                 className="w-full h-full object-cover"
    //                 src={post.media_url}
    //                 alt={post.title}
    //               />
    //               <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end justify-center opacity-0 transition-opacity duration-500 hover:opacity-100">
    //                 <div className="p-4 text-white text-left w-full bg-black bg-opacity-70">
    //                   <h3 className="text-lg font-bold">{post.title}</h3>
    //                   <p className="text-lg font-poppins">{post.caption}</p>
    //                 </div>
    //               </div>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* Button */}
    //   <div className="absolute top-2/3 right-64">
    //     <button
    //       href='#'
    //       class=" text-2xl  hover text-sky-400 after:content-['_↗'] ..." >View more Stories</button></div>
    // </div>
    
  );
};

export default InstagramPost;

