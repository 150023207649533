import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../css/animation.css';


const Experets = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 20000, // Transition speed in milliseconds
        slidesToShow: 10,
        slidesToScroll: 5,
        margin: 25,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 4000,
        arrows: false, 
    
    };

    const logos = [
        "https://webres-nu.vercel.app/react.png",
        "https://webres-nu.vercel.app/next.png",
        "https://webres-nu.vercel.app/vue.png",
        "https://webres-nu.vercel.app/flutter.png",
        "https://webres-nu.vercel.app/kotlin.png",
        "https://webres-nu.vercel.app/angular.png",
        "https://webres-nu.vercel.app/Javascript.png",
        "https://webres-nu.vercel.app/nodejs.png",
        "https://webres-nu.vercel.app/mongodb.png",
        "https://webres-nu.vercel.app/firebase.png",
        "https://webres-nu.vercel.app/googlecloud.png",
        "https://webres-nu.vercel.app/digitalocean.png",
        "https://webres-nu.vercel.app/kubernetes.png",
        "https://webres-nu.vercel.app/azure.png",
        "https://webres-nu.vercel.app/aws.png",
        "https://webres-nu.vercel.app/netlify.png",
        "https://webres-nu.vercel.app/vercel.png",
        "https://webres-nu.vercel.app/heroku.png",
        "https://webres-nu.vercel.app/jetbrains.png",
        "https://webres-nu.vercel.app/vscode.png",
        "https://webres-nu.vercel.app/figma.png",
    ];

    const logoItemStyle = {
        margin: '0 10px', // Increase or decrease as needed
    };

    return (
        <Slider {...settings}>
            {logos.map((logo, index) => (
                <div className="w-12 pb-32 md:w-16 lg:w-20" style={logoItemStyle} key={index}>
                    <img

                        src={logo}
                        alt={`Logo ${index + 1}`}
                        width={30}
                        height={30}
                    />
                </div>
            ))}
        </Slider>
    )
}

export default Experets