import React from 'react';
import Content from './section_02';
import Servise_answer from './section_03';
import Hero from "./hero_screen";
import Navbar from './navbar';
import Footer from './footer';
import Cookie_alert from './alerts/cookie_alert';
import './css/nav.css';
import './css/header.css';
import Success_stories from './section_04';
import Under404 from './under404';
import Getintouch from './getin_tuch';
import Make_a_call from './tech_talk';
import InstagramPost from '../services/Instagram';

export default function Mainpage() {


  return (
    <div>
      <Navbar />
      <Hero />
      <Content />
      <Servise_answer />
      <Success_stories />
      <Make_a_call />
      <InstagramPost/>
      <Getintouch />
      <Footer />
      <Cookie_alert />
    </div>
  )
}



